/** @jsx jsx */
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';
import ExhibitorsPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';

export default function ExhibitorsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, exhibitors, site } = afterQuery(data);
  const { filterSeekingDistribution, filterHasDistribution } = event || {};
  return (
    <LinkProvider value={pageContext.paths}>
      <ExhibitorsPage
        {...pageContext}
        // Make sure the page is remounted on a different href (i.e. a different query).
        // QUESTION: Why is this actually necessary? Because of the page's state?
        key={location.href}
        event={event}
        exhibitors={exhibitors}
        siteTitle={site?.siteMetadata?.title}
        facetConfig={{
          filterSeekingDistribution,
          filterHasDistribution,
          ...pageContext,
        }}
        location={location}
        navigate={navigate}
      />
    </LinkProvider>
  );
}

function afterQuery({ bottlebooks, event, firstExhibitors, exhibitors, site }) {
  const importersExhibitors = bottlebooks?.importers?.exhibitors?.nodes;
  const exportersExhibitors = bottlebooks?.exporters?.exhibitors?.nodes;

  // Enrich the first exhibitors with better images and base64 previews.
  const combinedExhibitors = exhibitors.nodes?.map((exhibitor, index) => {
    const refinedData = firstExhibitors?.nodes[index];
    const importers_exhibitor = importersExhibitors?.find(
      ({ exhibitorId }) => exhibitorId === exhibitor.exhibitorId
    );
    const exporters_exhibitor = exportersExhibitors?.find(
      ({ exhibitorId }) => exhibitorId === exhibitor.exhibitorId
    );
    return deepMerge(
      {},
      exhibitor,
      importers_exhibitor,
      exporters_exhibitor,
      refinedData
    );
  });

  return {
    event: bottlebooks?.theme,
    site,
    exhibitors: combinedExhibitors,
  };
}

export const pageQuery = graphql`
  query custom_ExhibitorsTemplate(
    $locale: Bottlebooks_ContentLocale
    $filter: ExhibitorFilterInput
    $sort: ExhibitorSortInput
    $localeString: String
  ) {
    bottlebooks {
      theme: event(eventId: "6048b4376a7c621a467c85d5", locale: $locale) {
        ...custom_ExhibitorsTemplate_Fragment
      }
      importers: event(eventId: "6033a2d31d00c321389bf5f7", locale: $locale) {
        ...custom_ExhibitorsTemplate_Fragment
      }
      exporters: event(eventId: "603f54d910828b19f691ff46", locale: $locale) {
        ...custom_ExhibitorsTemplate_Fragment
      }
    }

    event(locale: { eq: $localeString }) {
      ...ExhibitorsTemplate_Event
    }
    firstExhibitors: allExhibitor(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ExhibitorsPage_first
      }
    }
    exhibitors: allExhibitor(filter: $filter, sort: $sort) {
      nodes {
        exhibitorId
        ...ExhibitorsPage
      }
    }
    ...ExhibitorsTemplate_Query
  }

  fragment custom_ExhibitorsTemplate_Fragment on Bottlebooks_Event {
    exhibitors {
      nodes {
        exhibitorId
        ...bb_ExhibitorsPage
      }
    }
  }

  fragment ExhibitorsTemplate_Event on Event {
    filterHasDistribution
    filterSeekingDistribution
    ...ExhibitorsPage_Event
  }

  fragment ExhibitorsTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
